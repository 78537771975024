<template>
  <div
    class="deck-text-field"
    :class="classes"
  >
    <deck-label
      v-if="label || $slots.label"
      :text="label"
      :size="size === 'medium' ? 'small' : size"
      :input-ref="computedId"
      :hint="hint"
      class="w-stretch"
    >
      <slot name="label" />
    </deck-label>
    <div
      class="deck-text-field__content-wrapper"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <template v-if="readOnly">
        <slot name="prepend">
          <deck-icon
            v-if="prependIconName"
            :name="prependIconName"
            v-bind="prependIconProps"
            class="deck-text-field__prepend-icon deck-text-field__prepend-icon--read-only"
          />
        </slot>

        <span class="flex-1">
          {{ readOnlyDisplayValue || deckInputMixin__internalValue }}
        </span>

        <div class="deck-text-field__read-only-append">
          <deck-text-field-append-default
            v-if="hasDefaultAppend"
            :controls="controls"
            :tags="tags"
            :can-clear="canClear"
            :hovering="hover"
            :fixed-controls="fixedControls"
            :hide-clear="hideClear"
            @control:click="(...args) => $emit('control:click', ...args)"
            @control:hover="(...args) => $emit('control:hover', ...args)"
            @clear="deckInputMixin__internalValue = ''"
          />

          <!-- @slot Optional slot to render custom content at the end of the input, after the default append content (controls, tags, clear button) -->
          <slot name="append" />
        </div>
      </template>

      <component
        :is="vuetifyComponent"
        v-else
        ref="text-field"
        v-mask="mask"
        v-bind="{ ...textFieldProps, ...textFieldListeners }"
        class="deck-text-field__text-field"
      >
        <template
          v-if="prependIconName || $slots.prepend"
          #prepend-inner
        >
          <slot name="prepend">
            <deck-icon
              v-if="prependIconName"
              :name="prependIconName"
              v-bind="prependIconProps"
              class="deck-text-field__prepend-icon"
            />
          </slot>
        </template>

        <template
          v-if="hasDefaultAppend || $slots.append"
          #append-inner
        >
          <deck-text-field-append-default
            v-if="hasDefaultAppend"
            :controls="controls"
            :tags="tags"
            :can-clear="canClear"
            :hovering="hover"
            :fixed-controls="fixedControls"
            :hide-clear="hideClear"
            class="deck-text-field__default-editable-append"
            @control:click="(...args) => $emit('control:click', ...args)"
            @control:hover="(...args) => $emit('control:hover', ...args)"
            @clear="deckInputMixin__internalValue = ''"
          />
          <!-- @slot Optional slot to render custom content at the end of the input, after the default append content (controls, tags, clear button) -->
          <slot name="append" />
        </template>
      </component>
    </div>
  </div>
</template>

<script lang="ts">
import { mask } from 'vue-the-mask';
import type { VTextField as VTextFieldType } from 'vuetify/components/VTextField';
import deckInputMixin from '~/mixins/deckInputMixin';
import { VTextField, VTextarea } from 'vuetify/components';

export default {
  name: 'DeckTextField',
  components: {
    DeckIcon: defineAsyncComponent(() => import('~/deck/icon')),
    DeckTextFieldAppendDefault: defineAsyncComponent(() => import('./_append-default')),
    VTextField,
    VTextarea,
  },
  directives: {
    // Allow undefined mask to be passed to the directive
    // https://github.com/vuejs-tips/vue-the-mask/issues/82#issuecomment-661319448
    mask: (el, binding) => {
      if (!binding.value) return;
      mask(el, binding);
    },
  },
  mixins: [deckInputMixin],
  props: {
    vuetifyComponent: {
      type: String,
      default: 'v-text-field',
      validator: value => ['v-text-field', 'v-textarea'].includes(value),
    },
    /**
     * The label to display.
     * @type {string}
     * @default null
     */
    label: {
      type: String,
      default: null,
    },

    /**
     * The aria label to display when the label is not visible.
     * @type {string}
     * @default null
     */
    ariaLabel: {
      type: String,
      default: null,
    },

    /**
     * The value of the input.
     * @type {string|number}
     * @default null
     */
    // eslint-disable-next-line vue/no-unused-properties
    modelValue: {
      type: [String, Number],
      default: null,
    },

    /**
     * The size of the input.
     * @type {'dense' | 'medium' }
     * @default 'dense'
     */
    size: {
      type: String,
      default: 'dense',
    },

    /**
     * Whether the input is required.
     * @type {boolean}
     * @default false
     */
    required: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the input is disabled.
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * The hint to display.
     * @type {string}
     * @default null
     */
    hint: {
      type: String,
      default: null,
    },

    /**
     * The mask to apply to the input.
     * @type {string | Array}
     * @default undefined
     */
    mask: {
      type: [String, Array],
      default: undefined,
    },

    /**
     * The validation rules to apply to the input.
     * @type {Array}
     * @default []
     */
    rules: {
      type: Array,
      default: () => [],
    },

    /**
     * The type of the input.
     * @type {string}
     * @default 'text'
     */
    type: {
      type: String,
      default: 'text',
    },

    /**
     * The placeholder to display.
     * @type {string}
     * @default undefined
     */
    placeholder: {
      type: String,
      default: undefined,
    },

    /**
     * The min value of the input.
     * @type {string}
     * @default undefined
     */
    min: {
      type: String,
      default: undefined,
    },

    /**
     * The max value of the input.
     * @type {string}
     * @default undefined
     */
    max: {
      type: String,
      default: undefined,
    },

    /**
     * The autocomplete value of the input.
     * @type {string}
     * @default undefined
     */
    autocomplete: {
      type: String,
      default: undefined,
    },

    /**
     * The icon to prepend to the input.
     * @type {string | Object}
     * @default undefined
     */
    prependIcon: {
      type: [String, Object],
      default: undefined,
    },

    /**
     * The number of errors to display.
     * @type {number}
     * @default undefined
     */
    errorCount: {
      type: Number,
      default: undefined,
    },

    /**
     * Whether the input is loading.
     * @type {boolean}
     * @default false
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the input shows only its value non interactively and with
     * read-only styling.
     * @type {boolean}
     * @default false
     */
    readOnly: {
      type: Boolean,
      default: false,
    },

    /**
     * Visually hides the clear button even if the input is clearable.
     * Especially useful when having multiple controls that compete for space.
     * @type {boolean}
     * @default false
     */
    hideClear: {
      type: Boolean,
      default: false,
    },

    /**
     * An alternative value to display when the input is read-only. If not
     * provided, the `value` will be displayed as usual. Useful in situations
     * where you need to display a different value than the one used internally.
     * @type {string|number}
     * @default undefined
     */
    readOnlyDisplayValue: {
      type: [String, Number],
      default: undefined,
    },

    /**
     * Informational tags to display appended at the end of the component.
     * Defined as a list of objects of DeckChip props. This uses precious
     * real-estate, so use sparingly and preferrably only one.
     * @type {Array<DeckChipProps>}
     * @default undefined
     */
    tags: {
      type: [Array],
      default: undefined,
    },

    /**
     * Appended list of controls to display at the end of the component. Defined
     * as a list of objects of DeckButton props with an additional `controlName`
     * property to define the events to emit when a button is clicked or
     * hovered. Will be emitted as `control:click` and
     * `control:hover`, containing the controlName as the first argument.
     *
     * Additionally you may pass a function in the `action` property to bypass
     * the need for listening to the click event. This function will be called
     * on the control click.
     * @type {Array<DeckButtonProps & { controlName: string }>}
     * @default undefined
     */
    controls: {
      type: [Array],
      default: undefined,
    },

    /**
     * Whether the input has its custom controls always displayed regardless of
     * being hovered or not. This is also useful for triggering the rendering of
     * the controls programmatically. Clear button stays with the same hover
     * behavior but rendering first in this case.
     * @type {boolean}
     * @default false
     */
    fixedControls: {
      type: Boolean,
      default: false,
    },

    // necessary to check if the event is defined
    // related to this thread: https://github.com/vuejs/rfcs/discussions/397#discussioncomment-10893510
    // eslint-disable-next-line vue/prop-name-casing
    'onKeypress:enter': {
      type: Function,
      default: undefined,
    },
  },
  emits: [
    'blur',
    'click:append',
    'control:click',
    'control:hover',
    'focus',
    'keypress:enter',
    'update:modelValue',
  ],
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    classes() {
      return {
        [`deck-text-field--${this.size}`]: true,
        'deck-text-field--disabled': this.disabled,
        'deck-text-field--can-clear': this.canClear,
      };
    },

    computedId() {
      return `deck-text-field-${this.$.uid}`;
    },

    textFieldProps() {
      const props = {
        id: this.computedId,
        modelValue: this.deckInputMixin__internalValue,
        ariaLabel: this.label || this.ariaLabel,
        required: this.required,
        clearable: false,
        class: 'deck-text-field__text-field',
        variant: 'outlined',
        density: this.size === 'dense' ? 'compact' : 'default',
        hideDetails: 'auto',
        rules: this.rules,
        type: this.type,
        placeholder: this.placeholder,
        min: this.min,
        max: this.max,
        disabled: this.disabled,
        autocomplete: this.autocomplete,
        appendIcon: undefined,
        maxErrors: this.errorCount,
        loading: this.loading,
        autofocus: this.$attrs.autofocus,
      } as InstanceType<typeof VTextFieldType>;

      if (this.vuetifyComponent === 'v-textarea') {
        return {
          ...props,
          rows: this.$attrs.rows || 3,
          autoGrow: true,
        };
      }

      return props;
    },
    shouldListenToKeypress() {
      return Boolean(this['onKeypress:enter']);
    },
    textFieldListeners() {
      const listeners = {
        onFocus: (event) => {
          this.$emit('focus', event);
        },
        'onClick:append': (event) => {
          this.$emit('click:append', event);
        },
        'onUpdate:modelValue': this.onInput,
        onBlur: this.onBlur,
      };

      if (this.shouldListenToKeypress) {
        listeners.onKeypress = this.onKeypress;
      }

      return listeners;
    },

    canClear() {
      return !this.disabled && !this.deckInputMixin__isEmpty && !this.loading && !this.required && !this.readOnly;
    },

    hasTags() {
      return this.tags?.length > 0;
    },

    hasControls() {
      return this.controls?.length > 0;
    },

    hasDefaultAppend() {
      return this.hasTags || this.hasControls || this.canClear;
    },
    prependIconName() {
      return typeof this.prependIcon === 'string' ? this.prependIcon : this.prependIcon?.name;
    },
    prependIconProps() {
      const defaultProps = {
        size: 'small',
        fixedWidth: true,
      };
      return typeof this.prependIcon === 'object' ? { ...defaultProps, ...this.prependIcon } : defaultProps;
    },
  },
  methods: {
    onInput(value) {
      if (this.type === 'number') {
        this.deckInputMixin__internalValue = _toNumber(value);
        return;
      }

      this.deckInputMixin__internalValue = value;
    },
    onBlur(event) {
      if (this.required && this.deckInputMixin__isEmpty) {
        this.deckInputMixin__resetInternalValue();
      }

      this.$emit('blur', event);
    },
    onKeypress(event) {
      if (event.key === 'Enter' && !event.shiftKey && !event.altKey && !event.ctrlKey) {
        event.preventDefault();
        this.$emit('keypress:enter', event);
      }
    },
  },
};
</script>

<style lang="scss">
.deck-text-field {
  --deck-text-field-border-color: var(--z-input-border-color);
  --deck-text-field-input-color: var(--z-color-text);

  .v-field {
    font-size: var(--deck-text-field-font-size) !important;
  }

  .v-input__details {
    padding: 0 !important;
  }
}

.deck-text-field__content-wrapper {
  align-items: center;
  color: var(--deck-text-field-input-color);
  display: flex;
  font-size: var(--deck-text-field-font-size) !important;
  font-weight: 400;
  gap: var(--z-s1);
  line-height: var(--z-line-height-base);
}

.deck-text-field__read-only-append {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  user-select: none;
}

.deck-text-field__default-editable-append {
  margin-right: var(--z-s1-n);
}

.deck-text-field__text-field {
  .v-field__overlay {
    background-color: var(--z-input-background-color);
  }

  .v-field__outline {
    color: var(--deck-text-field-border-color);
  }

  .v-field--variant-outlined & {
    &__start,
    &__notch::before,
    &__notch::after,
    &__end {
      transition: all 200ms ease !important
    }
  }

  .v-text-field__slot input, .v-text-field__slot textarea {
    color: var(--deck-text-field-input-color) !important;
    caret-color: var(--deck-text-field-input-color) !important;
  }

  &:is(:hover, :focus-within, .v-input--is-focused):not(.v-input--is-disabled) {
    --deck-text-field-border-color: var(--z-input-border-color-highlight);
  }
}

.deck-text-field--dense {
  --deck-text-field-font-size: var(--z-font-size-small);
}

.deck-text-field--medium {
  --deck-text-field-font-size: var(--z-font-size-medium);
}
</style>
